import { createStore } from 'vuex'
import Auth from './Auth.js'
import Yoyaku from './Yoyaku.js'

export default createStore({
  state: {
    processing: false
  },
  getters: {
    processing: (state) => state.processing
  },
  mutations: {
    processing (state, processing) {
      state.processing = processing
    }
  },
  actions: {
  },
  modules: {
    Auth,
    Yoyaku
  }
})
