<template>
  <div id="modal">
    <div class="modal-inner">
      <h2>診察予約</h2>
      <fieldset>
        <input
          type="number"
          v-model="data.patientNum"
          placeholder="診察券番号"
          :class="{'is-invalid': !states.patientNum}"
        >
        <p class="error">診察券番号を入力してください</p>
      </fieldset>
      <fieldset>
        <input
          type="text"
          v-model="data.name"
          placeholder="お名前"
          :class="{'is-invalid': !states.name}"
        >
        <p class="error">お名前を入力してください</p>
      </fieldset>
      <fieldset>
        <input
          type="tel"
          v-model="data.telNum"
          placeholder="携帯電話番号"
          :class="{'is-invalid': !states.telNum}"
        >
        <p class="error">携帯電話番号を入力してください</p>
      </fieldset>
      <fieldset class="half">
        <label>
          <input
            type="checkbox"
            v-model="hasFever"
          >発熱あり
        </label>
        <label>
          <input
            type="checkbox"
            v-model="noFever"
          >発熱なし
        </label>
      </fieldset>
      <fieldset class="sleeve">
        <button
          class="cancel"
          @click="$emit('close')"
        >キャンセル</button>
        <button
          class="submit"
          @click="placeBooking"
        >予約登録</button>
      </fieldset>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddBooking',
  emits: ['close'],
  data () {
    return {
      data: {
        name: '',
        patientNum: '0000',
        telNum: '000-000-0000',
        hasFever: false
      },
      states: {
        name: true,
        patientNum: true,
        telNum: true
      }
    }
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters['Yoyaku/updated'],
      (newValue) => {
        this.$emit('close')
        this.$store.commit('Yoyaku/updated', false)
      }
    )
  },
  methods: {
    validate () {
      this.states.name = !!this.data.name
      this.states.patientNum = !!this.data.patientNum
      this.states.telNum = !!this.data.telNum
      let res = true
      const states = { ...this.states }
      Object.keys(states).forEach(function (key) {
        res *= states[key]
      })
      return res
    },
    placeBooking (e) {
      e.preventDefault()
      if (this.validate()) {
        this.$store.dispatch('Yoyaku/addYoyaku', this.data)
      }
    }
  },
  computed: {
    hasFever: {
      get: function () {
        return this.data.hasFever
      },
      set: function (newVal) {
        this.data.hasFever = newVal
      }
    },
    noFever: {
      get: function () {
        return !this.data.hasFever
      },
      set: function (newVal) {
        this.data.hasFever = !newVal
      }
    }
  }
}
</script>

<style scoped>
  h2 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0.5em;
  }
  fieldset {
    margin-bottom: 15px;
  }
  .sleeve {
    display: grid;
    grid-template-columns: repeat( 2, 1fr );
    grid-auto-flow: dense;
    grid-gap: 5px;
    padding-top: 10px;
  }
  .sleeve button {
    padding: 15px;
    border-radius: 4px;
    background-color: #bbb;
  }
  .sleeve button.submit {
    background-color: #3176f6;
    color: #fff;
  }
  .sleeve button:hover {
    cursor: pointer;
  }
  p.error {
    display: none;
    color: #c00;
  }
  .is-invalid + p.error {
    display: block;
  }
  p.error::before {
    content: "※";
  }
  .half {
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat( 2, 1fr );
  }
  .half label {
    display: flex;
    align-items: center;
  }
  .half input[type=checkbox] {
    width: 25px;
    height: 25px;
    margin-right: 0.5em;
  }
</style>
