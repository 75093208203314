import axios from 'axios'
import $ from 'jquery'

const state = {
  waitingList: {},
  todaysTotalList: {},
  numWaiting: '',
  numTotal: '',
  updated: false,
  chime: false
}

const getters = {
  waitingList: (state) => state.waitingList,
  todaysTotalList: (state) => state.todaysTotalList,
  numWaiting: (state) => state.numWaiting,
  numTotal: (state) => state.numTotal,
  updated: (state) => state.updated,
  chime: (state) => state.chime
}

const mutations = {
  waitingList (state, waitingList) {
    state.waitingList = waitingList
  },
  todaysTotalList (state, todaysTotalList) {
    state.todaysTotalList = todaysTotalList
  },
  numWaiting (state, numWaiting) {
    state.numWaiting = numWaiting
  },
  numTotal (state, numTotal) {
    state.numTotal = numTotal
  },
  updated (state, updated) {
    state.updated = updated
  },
  chime (state, chime) {
    state.chime = chime
  }
}

const actions = {
  getWaitingList ({ commit }) {
    axios
      .get('/wp-json/spr/v1/current/list/waiting')
      .then((response) => {
        console.log(response.data)
        commit('waitingList', response.data)
        commit('numWaiting', response.data.length)
      })
      .catch(error => {
        console.log(error)
      })
  },
  getTodaysTotalList ({ getters, commit }) {
    // const sound = new Audio(require('@/assets/double_beep.mp3'))
    // const num = getters.numTotal
    axios
      .get('/wp-json/spr/v1/current/list/entire')
      .then((response) => {
        console.log(response.data)
        commit('todaysTotalList', response.data)
        // if (getters.chime && num < response.data.length) {
        //   sound.play()
        // }
        commit('numTotal', response.data.length)
      })
      .catch(error => {
        console.log(error)
      })
  },
  async addYoyaku ({ commit, dispatch }, data) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      commit('chime', false)
      axios
        .post('/wp-json/spr/v1/yoyaku/add', {
          data: data
        })
        .then(response => {
          console.log(response)
          dispatch('getWaitingList')
          dispatch('getTodaysTotalList')
          commit('updated', true)
          commit('chime', true)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  updateQueue ({ dispatch }) {
    const ar1 = []
    const ar2 = []
    $('.patientlist .listbody li').each(function () {
      const callnum = $(this).find('.callnum').html()
      if ($(this).find('.r1').is(':checked')) {
        ar1.push(parseInt(callnum))
      }
      if ($(this).find('.r2').is(':checked')) {
        ar2.push(parseInt(callnum))
      }
    })
    axios
      .post('/wp-json/spr/v1/room/queue', {
        room1: ar1,
        room2: ar2
      })
      .then((response) => {
        console.log(response)
        dispatch('getWaitingList')
        dispatch('getTodaysTotalList')
      })
      .catch(error => {
        console.log(error)
      })
  },
  updateQueuePartial ({ dispatch }, data) {
    axios
      .post('/wp-json/spr/v1/update/roomqueue/partial', data)
      .then((response) => {
        console.log(response)
        dispatch('getWaitingList')
        dispatch('getTodaysTotalList')
      })
      .catch(error => {
        console.log(error)
      })
  },
  toggleCalled ({ dispatch }, data) {
    if (data.status === '1') {
      data.status = true
    } else {
      data.status = false
    }
    axios
      .post('/wp-json/spr/v1/status/called', {
        pnum: data.pnum,
        callnum: data.callnum,
        status: data.status
      })
      .then((response) => {
        console.log(response)
        dispatch('getWaitingList')
        dispatch('getTodaysTotalList')
      })
      .catch(error => {
        console.log(error)
      })
  },
  toggleRecorded ({ dispatch }, data) {
    if (data.status === '1') {
      data.status = true
    } else {
      data.status = false
    }
    axios
      .post('/wp-json/spr/v1/status/recorded', {
        pnum: data.pnum,
        callnum: data.callnum,
        status: data.status
      })
      .then((response) => {
        console.log(response)
        dispatch('getWaitingList')
        dispatch('getTodaysTotalList')
      })
      .catch(error => {
        console.log(error)
      })
  },
  deleteRow ({ commit, dispatch }, data) {
    if (confirm(`${data.callnum} ${data.pname}
    予約を削除しますか？`)) {
      axios
        .post('/wp-json/spr/v1/delete', {
          pnum: data.pnum,
          callnum: data.callnum
        })
        .then((response) => {
          console.log(response)
          dispatch('getWaitingList')
          dispatch('getTodaysTotalList')
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  haltRow ({ commit, dispatch }, data) {
    axios
      .post('/wp-json/spr/v1/halt', {
        pnum: data.pnum,
        callnum: data.callnum,
        halt: data.halt
      })
      .then((response) => {
        console.log(response)
        // dispatch('getWaitingList')
        // dispatch('getTodaysTotalList')
      })
      .catch(error => {
        console.log(error)
      })
  }
}

const Yoyaku = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default Yoyaku
