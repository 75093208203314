<template>
  <div class="patientlist">
    <ul>
      <li class="indexrow">
        <span class="halt">有効</span>
        <span class="s1">削除</span>
        <span class="s1 callnum">No.</span>
        <span class="s1">診１</span>
        <span class="s1">診２</span>
        <span class="s2">診察券番号</span>
        <span class="s4">お名前</span>
        <span class="s4">TEL</span>
        <span class="s1">呼出済</span>
        <span class="s1">記録済</span>
      </li>
    </ul>
    <ul class="listbody">
      <DataRowPatient
        v-for="patient in list"
        :key="patient.call_number"
        :patient="patient"
        :selectedRow="selected"
        @deleteRow="deleteRow"
        @updateQueue="setOrder"
        @toggleCalled="toggleCalled"
        @toggleRecorded="toggleRecorded"
        @selected="setSelectedRow"
      />
    </ul>
  </div>

</template>

<script>
import DataRowPatient from '@/components/DataRowPatient'
export default {
  components: {
    DataRowPatient
  },
  props: ['reload'],
  emits: ['done'],
  data () {
    return {
      list: {},
      selected: ''
    }
  },
  mounted () {
    this.list = this.$store.getters['Yoyaku/waitingList']
    this.$store.watch(
      (state, getters) => getters['Yoyaku/waitingList'],
      (newValue) => {
        this.list = newValue
      }
    )
    const container = this.$el.querySelector('.listbody')
    this.$nextTick(function () {
      container.scrollTop = container.scrollHeight
    })
  },
  methods: {
    deleteRow () {},
    setOrder () {},
    toggleCalled () {},
    toggleRecorded () {},
    setSelectedRow (callNum) {
      this.selected = callNum
    }
  }
}
</script>
