import axios from 'axios'
import router from '../router'

const baseUrl = 'https://smoothpx.com/'
axios.defaults.baseURL = baseUrl

const state = {
  authData: null,
  token: null,
  branchSlug: null,
  branchTitle: null,
  isAuthenticated: false,
  capability: null
}

const getters = {
  authData: (state) => state.authData,
  token: (state) => state.token,
  branchTitle: (state) => state.branchTitle,
  branchSlug: (state) => state.branchSlug,
  isAuthenticated: (state) => state.isAuthenticated,
  capability: (state) => state.capability
}

const mutations = {
  authData (state, authData) {
    state.authData = authData
  },
  token (state, token) {
    state.token = token
    localStorage.setItem('token', token)
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  },
  branchTitle (state, branchTitle) {
    state.branchTitle = branchTitle
    localStorage.setItem('branchTitle', branchTitle)
  },
  branchSlug (state, branchSlug) {
    state.branchSlug = branchSlug
    localStorage.setItem('branchSlug', branchSlug)
    axios.defaults.baseURL = baseUrl + branchSlug
  },
  isAuthenticated (state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated
    localStorage.setItem('authenticated', true)
  },
  capability (state, capability) {
    state.capability = capability
    localStorage.setItem('capability', capability)
  }
}

const actions = {
  // マスターログイン
  async login ({ commit, dispatch }, authData) {
    console.log('Attempting login master branch')
    commit('authData', authData)
    try {
      axios.defaults.headers.common.Authorization = ''
      axios.defaults.baseURL = baseUrl
      await axios
        .post('/wp-json/jwt-auth/v1/token', {
          username: authData.username,
          password: authData.password
        })
        .then((response) => {
          commit('token', response.data.token)
          commit('capability', response.data.capability)
          commit('branchSlug', response.data.belong[0].slug)
          dispatch('branchLogin')
        })
    } catch (error) {
      const {
        status,
        statusText
      } = error.response
      console.log(`Error! HTTP Status: ${status} ${statusText}`)
    }
  },

  // ブランチログイン
  async branchLogin ({ getters, commit }) {
    console.log('Attempting login to child branch')
    axios.defaults.headers.common.Authorization = ''
    try {
      await axios
        .post('/wp-json/jwt-auth/v1/token', {
          username: getters.authData.username,
          password: getters.authData.password
        })
        .then((response) => {
          console.log('Success login child branch')
          commit('token', response.data.token)
          commit('isAuthenticated', true)
          localStorage.setItem('username', getters.authData.username)
          localStorage.setItem('password', getters.authData.password)
          router.push('/')
        })
    } catch (error) {
      const {
        status,
        statusText
      } = error.response
      console.log(`Error! HTTP Status: ${status} ${statusText}`)
    }
  },

  // 自動ログイン
  autoLogin ({ commit, dispatch }) {
    console.log('try auto login')
    commit('token', localStorage.getItem('token'))
    commit('branchSlug', localStorage.getItem('branchSlug'))
    commit('branchTitle', localStorage.getItem('branchTitle'))
    commit('capability', localStorage.getItem('capability'))
    dispatch('varidateToken')
  },

  // Token有効性確認
  varidateToken ({ commit }) {
    axios
      .post('/wp-json/jwt-auth/v1/token/validate')
      .then(() => {
        console.log('Token Validation success')
        commit('isAuthenticated', true)
        router.push('/')
      })
      .catch(() => {
        console.log('Token Validation error')
        commit('isAuthenticated', false)
      })
  },

  // ログアウト
  logout ({ commit }) {
    commit('isAuthenticated', false)
    router.push('/login')
  }
}

const Auth = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default Auth
