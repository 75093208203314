<template>
  <div class="home">

    <keep-alive>
      <ListWaitingExam />
    </keep-alive>

    <button
      class="addBooking"
      @click="openModal"
    >+診察予約追加</button>

    <Modal
      v-if="modal"
      @close="closeModal"
    />

  </div>
</template>

<script>
import ListWaitingExam from '@/components/ListWaitingExam'
import Modal from '@/components/Modal.vue'

export default {
  name: 'HomeView',
  components: {
    ListWaitingExam,
    Modal
  },
  data () {
    return {
      modal: false
    }
  },
  methods: {
    openModal () {
      this.modal = true
    },
    closeModal () {
      this.modal = false
    }
  }
}
</script>
