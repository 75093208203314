<template>
  <div v-if="slug === 'Login'">
    <router-view />
  </div>
  <div v-else>
    <div
      id="card"
      :class="{hc: highContrastMode}"
    >
      <div>
        <ul class="tabs">
          <li
            :class="{ active: isListWaiting }"
            @click="playSound1"
          ><router-link to="/">診察待ち</router-link></li>
          <li
            :class="{ active: isListTotalToday }"
            @click="playSound1"
          ><router-link to="/TotalToday">本日の全受診者</router-link></li>
          <li
            :class="{ active: isDoctorSelect }"
            @click="playSound1"
          ><router-link to="/Doctor">今日の担当医師</router-link></li>
          <li
            @click="playSound1"
          ><router-link to="/login">ログアウト</router-link></li>
        </ul>
        <dl id="status">
          <dt>待ち</dt><dd>{{numWaiting}}</dd>
          <dt>本日</dt><dd>{{numTotal}}</dd>
        </dl>
        <router-view />
      </div>
    </div>
  </div>
  <div
    class="highcontrast"
    :class="{active: highContrastMode}"
    @click="toggleHighContrast"
  ></div>
</template>

<script>
import axios from 'axios'
export default {
  data () {
    return {
      slug: '',
      isAuthenticated: false,
      waitingList: {},
      totalList: {},
      numWaiting: 0,
      numTotal: 0,
      timer: 0,
      intervalId: '',
      soundEffect1: new Audio(require('@/assets/se-click2.mp3')),
      highContrastMode: false
    }
  },
  mounted () {
    this.numWaiting = this.$store.getters['Yoyaku/numWaiting']
    this.numTotal = this.$store.getters['Yoyaku/numTotal']

    this.$store.dispatch('Yoyaku/getWaitingList')
    this.$store.dispatch('Yoyaku/getTodaysTotalList')

    this.$store.watch(
      (state, getters) => getters['Auth/isAuthenticated'],
      (newValue) => {
        this.isAuthenticated = newValue
      }
    )

    this.$store.watch(
      (state, getters) => getters['Yoyaku/numWaiting'],
      (newValue) => {
        this.numWaiting = newValue
      }
    )

    this.$store.watch(
      (state, getters) => getters['Yoyaku/numTotal'],
      (newValue) => {
        this.numTotal = newValue
      }
    )

    const App = document.getElementById('app')
    App.addEventListener('mousemove', () => {
      this.timer = 0
    })
    this.$store.commit('Yoyaku/chime', true)
    this.intervalId = setInterval(this.periodicLoad, 1000)

    const body = document.querySelector('body')
    this.$store.watch(
      (state, getters) => getters.processing,
      (newValue) => {
        if (newValue) {
          body.classList.add('processing')
        } else {
          body.classList.remove('processing')
        }
      }
    )
  },
  methods: {
    periodicLoad () {
      this.timer += 1
      if (this.timer > 60) {
        this.$store.dispatch('Yoyaku/getWaitingList')
        this.$store.dispatch('Yoyaku/getTodaysTotalList')
        this.listenReloadRequest()
        this.timer = 0
      }
    },
    playSound1 () {
      this.soundEffect1.play()
    },
    listenReloadRequest () {
      axios
        .get('/wp-json/spr/v1/forcereload/dashboard')
        .then((response) => {
          if (response.data) {
            location.reload()
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    toggleHighContrast () {
      this.highContrastMode = !this.highContrastMode
      const body = document.getElementsByTagName('body')
      if (body[0].classList.contains('hc')) {
        body[0].classList.remove('hc')
      } else {
        body[0].classList.add('hc')
      }
    }
  },
  computed: {
    isListWaiting () {
      return this.slug === 'Home'
    },
    isDoctorSelect () {
      return this.slug === 'Doctor'
    },
    isListTotalToday () {
      return this.slug === 'TotalToday'
    }
  },
  watch: {
    $route: function (to) {
      this.slug = to.name
    }
  }
}
</script>
