<template>
  <div class="form-signin">
    <form id="loginform">
      <div>
        <h1>SmoothPX</h1>
        <input
          type="text"
          placeholder="ユーザー名"
          v-model="authData.username"
        >
        <input
          type="password"
          placeholder="パスワード"
          v-model="authData.password"
        >
        <button
          type="submit"
          @click.prevent="login"
        >ログイン</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'LoginForm',
  data () {
    return {
      authData: {
        username: '',
        password: ''
      }
    }
  },
  mounted () {
    this.authData.username = localStorage.getItem('username')
    this.authData.password = localStorage.getItem('password')
    this.$store.dispatch('Auth/logout')
    if (this.$store.getters['Auth/authData']) {
      this.authData = this.$store.getters['Auth/authData']
    }
  },
  methods: {
    login () {
      this.$store.dispatch('Auth/login', this.authData)
    }
  }
}
</script>

<style scoped>
  .form-signin {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .form-signin form {
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    position: relative;
    top: -10vh;
  }
  .form-signin form > div {
    width: 400px;
    max-width: 80%;
  }
  h1 {
    margin: 0 0 5px;
  }
  input[type=text],
  input[type=password],
  select {
    padding: 8px;
    font-size: 20px;
    display: block;
    margin-bottom: 15px;
    box-sizing: border-box;
    width: 100%;
  }
  button {
    display: block;
    padding: 8px;
    box-sizing: border-box;
    width: 100%;
  }
</style>
