<template>
  <div class="total-today">
    <keep-alive>
      <ListTotalToday />
    </keep-alive>
  </div>
</template>

<script>
import ListTotalToday from '@/components/ListTotalToday'
export default {
  name: 'TotalToday',
  data () {
    return {
      title: '今日の全受診者'
    }
  },
  components: {
    ListTotalToday
  }
}
</script>
