<template>
  <li
    :class="{emphasise: isOnlineReservation, infectionSuspected: infectionSuspected}"
  >
    <span class="halt">
      <div
        class="toggle"
        :class="{checked: isHalt}"
        @click="toggleHalt"
      >
        <input
          type="checkbox"
          v-model="isHalt"
        >
      </div>
    </span>
    <span class="s1">
      <button
        class="delete"
        @click="deleteRow"
      ><span>×</span></button>
    </span>
    <span class="s1 callnum">{{patient.call_number}}</span>
    <span class="s1 chkbox">
      <input
        type="checkbox"
        v-model="r1"
        @change="updateQueue"
        class="r1"
      >
    </span>
    <span class="s1 chkbox">
      <input
        type="checkbox"
        v-model="r2"
        @change="updateQueue"
        class="r2"
      >
    </span>
    <span class="s2">{{patient.patientCardNumber}}</span>
    <span
      class="s4 name"
      :title="symptoms"
      @click="toggleSelect"
    >{{patient.name}}
      <div v-if="symptomsArr.length > 0">
        <div
          v-for="(symptom, index) in symptomsArr"
          :key="index"
        >{{symptom}}</div>
      </div>
    </span>
    <span class="s4">{{patient.tel}}</span>
    <span class="s1">
      <button
        class="status"
        :class="{done: isCalled}"
        @click="toggleCalled"
      >{{labelIsCalled}}</button>
    </span>
    <span class="s1">
      <button
        class="status"
        :class="{done: isRecorded}"
        @click="toggleRecorded"
      >{{labelIsRecorded}}</button>
    </span>
  </li>
</template>

<script>
export default {
  props: ['patient', 'selectedRow'],
  emits: ['updateQueue', 'toggleCalled', 'toggleRecorded', 'selected'],
  data () {
    return {
      r1: false,
      r2: false,
      isHalt: false,
      isCalled: parseInt(this.$props.patient.IsCalled),
      isChecked: parseInt(this.$props.patient.IsAccounted),
      isRecorded: parseInt(this.$props.patient.IsRecorded),
      isOnlineReservation: parseInt(this.$props.patient.IsOnlineReservation),
      infectionSuspected: false,
      symptoms: '',
      symptomsArr: []
    }
  },
  mounted () {
    this.r1 = this.$props.patient.room1
    this.r2 = this.$props.patient.room2
    this.isHalt = parseInt(this.$props.patient.IsOnHold)
    const questionnaire = { ...this.$props.patient.questionnaire }
    if (Object.keys(questionnaire).length > 0) {
      this.infectionSuspected = true
    }
  },
  methods: {
    deleteRow () {
      this.$store.dispatch('Yoyaku/deleteRow', {
        pname: this.$props.patient.name,
        pnum: this.$props.patient.patientCardNumber,
        callnum: this.$props.patient.call_number
      })
    },
    toggleHalt () {
      console.log('halt!')
      this.isHalt = !this.isHalt
      this.$store.dispatch('Yoyaku/haltRow', {
        pnum: this.$props.patient.patientCardNumber,
        callnum: this.$props.patient.call_number,
        halt: this.isHalt
      })
    },
    updateQueue () {
      let room
      if (this.r1 === true) {
        room = 1
      }
      if (this.r2 === true) {
        room = 2
      }
      this.$store.dispatch('Yoyaku/updateQueuePartial', {
        callnum: this.$props.patient.call_number,
        room: room
      })
    },
    toggleCalled () {
      this.isCalled = !this.isCalled
      this.$store.dispatch('Yoyaku/toggleCalled', {
        pnum: this.$props.patient.patientCardNumber,
        callnum: this.$props.patient.call_number,
        status: this.$props.patient.IsCalled
      })
    },
    toggleRecorded () {
      this.isRecorded = !this.isRecorded
      this.$store.dispatch('Yoyaku/toggleRecorded', {
        pnum: this.$props.patient.patientCardNumber,
        callnum: this.$props.patient.call_number,
        status: this.$props.patient.IsRecorded
      })
    },
    toggleSelect () {
      const callNum = this.$props.patient.call_number
      this.$emit('selected', callNum)
    }
  },
  computed: {
    labelIsCalled () {
      return this.isCalled ? '呼出済' : '未'
    },
    labelIsChecked () {
      return this.isAccounted ? '会計済' : '未'
    },
    labelIsRecorded () {
      return this.isRecorded ? '記録済' : '未'
    }
  },
  watch: {
    r1: function (newVal) {
      this.r2 = !this.r1
    },
    r2: function (newVal) {
      this.r1 = !this.r2
    },
    infectionSuspected: {
      handler: function (newVal) {
        if (newVal) {
          if (this.$props.patient.questionnaire.hasFever === '1') {
            this.symptoms += '発熱\n'
            this.symptomsArr.push('発熱')
          }
          if (this.$props.patient.questionnaire.hasDigestiveSymptoms === '1') {
            this.symptoms += '下痢嘔吐\n'
            this.symptomsArr.push('下痢嘔吐')
          }
          if (this.$props.patient.questionnaire.hasRespiratorySymptoms === '1') {
            this.symptoms += '咳、喉の痛み\n'
            this.symptomsArr.push('咳、喉の痛み')
          }
          if (this.$props.patient.questionnaire.hasSnot === '1') {
            this.symptoms += '鼻水\n'
            this.symptomsArr.push('鼻水')
          }
          if (this.$props.patient.questionnaire.hasCrossBorderContact === '1') {
            this.symptoms += '県外往来\n'
            this.symptomsArr.push('県外往来')
          }
          if (this.$props.patient.questionnaire.hasVirusExposure === '1') {
            this.symptoms += '陽性者接触\n'
            this.symptomsArr.push('陽性者接触')
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  selectedRow: function (newVal) {
    console.log(newVal)
  }
}
</script>
