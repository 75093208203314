import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import jQuery from 'jquery'
import '@/assets/style.css'

store.dispatch('Auth/autoLogin').then(() => {
  createApp(App)
    .use(store)
    .use(router)
    .use(jQuery)
    .mount('#app')
})
