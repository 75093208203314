<template>
  <h2 class="sectiontitle">担当医師選択</h2>
  <div class="doctorselect">
    <div>
      <h3><span><em>第１</em>診察室</span></h3>
      <ul>
        <DoctorPane
          v-for="doctor in doctors"
          :key="doctor.id"
          :roomId="1"
          :doctor="doctor"
          :tantou="tantou.r1"
          @chooseDoctor="chooseDoctor"
        />
      </ul>
    </div>
    <div>
      <h3><span><em>第２</em>診察室</span></h3>
      <ul>
        <DoctorPane
          v-for="doctor in doctors"
          :key="doctor.id"
          :roomId="2"
          :doctor="doctor"
          :tantou="tantou.r2"
          @chooseDoctor="chooseDoctor"
        />
      </ul>
    </div>
  </div>
</template>

<script>
import DoctorPane from '@/components/DoctorPane'
import axios from 'axios'
const oonaxios = axios.create({
  baseURL: 'https://ohno-iin.jp'
})
export default {
  name: 'DoctorList',
  components: {
    DoctorPane
  },
  data () {
    return {
      doctors: {},
      tantou: {}
    }
  },
  mounted () {
    this.getTantou()
    this.getDoctors()
  },
  methods: {
    getDoctors () {
      oonaxios
        .get('/wp-json/onc/v1/doctors')
        .then((response) => {
          this.doctors = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    getTantou () {
      axios
        .get('/wp-json/spr/v1/room/tantou')
        .then((response) => {
          console.log(response.data)
          this.tantou = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    chooseDoctor (roomId, docId) {
      switch (roomId) {
        case 1:
          this.tantou.r1 = docId
          break
        case 2:
          this.tantou.r2 = docId
          break
      }
      axios
        .post('/wp-json/spr/v1/room/tantou', {
          room: roomId,
          doctor: docId
        })
        .then((response) => {
          console.log(response)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>
