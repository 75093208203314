<template>
  <li
    @click="chooseDoctor"
    :class="{selected: selected}"
  >
    <picture>
      <img :src="doctor.thumb_url">
    </picture>
    <div>
      {{doctor.name}}
    </div>
  </li>
</template>

<script>
export default {
  props: ['roomId', 'doctor', 'tantou'],
  emits: ['chooseDoctor'],
  data () {
    return {
      selected: false
    }
  },
  methods: {
    chooseDoctor () {
      if (this.selected) {
        this.selected = false
        this.$emit('chooseDoctor', this.$props.roomId, '')
      } else {
        this.$emit('chooseDoctor', this.$props.roomId, this.$props.doctor.id)
      }
    }
  },
  watch: {
    tantou (newVal) {
      if (newVal) {
        this.selected = parseInt(newVal) === parseInt(this.$props.doctor.id)
      }
    }
  }
}
</script>
